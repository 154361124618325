<template>
  <v-navigation-drawer
    fixed
    clipped
    app
    v-model="drawer"
    class="overflow-y-auto"
  >
    <v-list>
      <router-link to="/websitesoverview">
        <v-list-item v-if="userIsAdministrator">
          <v-list-item-action class="mr-4">
            <v-icon>mdi-laptop</v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-subtitle>
              {{ $t("Modules") }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </router-link>
      <router-link to="/usersoverview">
        <v-list-item v-if="userIsAdministrator || userIsUserManager">
          <v-list-item-action class="mr-4">
            <v-icon>mdi-account-circle</v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-subtitle>
              {{ $t("Users") }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </router-link>
      <router-link to="/settings">
        <v-list-item v-if="userIsAdministrator">
          <v-list-item-action class="mr-4">
            <v-icon>mdi-cog</v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-subtitle>
              {{ $t("Settings") }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </router-link>
      <v-divider v-if="userIsAdministrator || userIsUserManager" />

      <v-list-item>
        <v-list-item-action class="mr-4 mb-0 mt-5">
          <v-icon>mdi-laptop</v-icon>
        </v-list-item-action>

        <v-list-item-content>
          <!-- Show dropdown when there are multiple websites -->
          <template v-if="websites">
            <v-select
              v-if="websites.length > 1"
              :items="websites"
              :value="selectedWebsite"
              :label="$t('Select a module')"
              v-model="defaultSelected"
              single-line
              menu-props="bottom"
              item-value="_id"
              item-text="Name"
              @change="setSelectedWebsite(defaultSelected)"
              class="websiteSelect"
              hide-details
            ></v-select>
            <!-- Show label when there is only one website -->
            <v-list-item-subtitle class="mt-2 pt-3" v-if="websites.length == 1">
              {{ websites[0].Name }}
            </v-list-item-subtitle>
          </template>
        </v-list-item-content>
      </v-list-item>
      <template v-if="selectedWebsite && currentLanguage">
        <v-list-item v-if="checkForPages()">
          <v-list-item-icon class="mx-0 my-auto mr-4">
            <v-icon> mdi-magnify </v-icon>
          </v-list-item-icon>
          <v-slide-x-transition>
            <v-text-field
              :label="$t('Search')"
              clearable
              v-model="navSearchValue"
            ></v-text-field>
          </v-slide-x-transition>
        </v-list-item>
      </template>
      <template v-if="selectedWebsite && currentLanguage">
        <template v-for="(page, index) in selectedWebsiteSortedPages">
          <template v-if="page.Enabled && !page.IsSubPage">
            <v-list :key="index" class="py-0">
              <template v-if="page.SubPages.length > 0">
                <v-hover v-slot="{ hover }">
                  <v-list-item
                    @click="goToRoute('editPage', page._id.$oid, page)"
                    :class="[
                      hover && !page['Group']
                        ? 'menu-item-hover'
                        : 'menu-item-hover-group',
                    ]"
                  >
                    <v-layout align-center fill-height justify-space-between>
                      <router-link
                        :is="page.Group ? 'span' : 'router-link'"
                        :key="page._id.$oid"
                        :to="{
                          name: 'editPage',
                          params: { id: page._id.$oid },
                        }"
                      >
                        <v-layout>
                          <v-list-item-icon
                            v-if="page.Icon"
                            class="my-0 mr-4"
                            active
                          >
                            <v-icon class="menu-icon">{{ page.Icon }}</v-icon>
                          </v-list-item-icon>
                          <div class="pl-10" v-else>
                            <v-spacer></v-spacer>
                          </div>
                          <v-list-item-title
                            v-if="page.Name[currentLanguage].length == 0"
                            class="text-truncate black--text maxtext-width"
                          >
                            {{ page.Name[Object.keys(page.Name)[0]] }}
                          </v-list-item-title>
                          <v-list-item-title
                            v-else
                            class="text-truncate black--text maxtext-width"
                          >
                            {{ page.Name[currentLanguage] }}
                          </v-list-item-title>
                        </v-layout>
                      </router-link>
                      <template v-if="checkForIconUpDown(page)">
                        <v-btn icon @click="slideOpen(index)">
                          <v-icon>
                            {{
                              openPage[index]
                                ? "mdi-chevron-up"
                                : "mdi-chevron-down"
                            }}
                          </v-icon>
                        </v-btn>
                      </template>
                    </v-layout>
                  </v-list-item>
                </v-hover>
                <v-expand-transition>
                  <v-sheet v-if="openPage[index]" height="auto">
                    <div
                      :key="'sub-pages-container' + page._id.$oid"
                      v-if="page.SubPages.length > 0"
                    >
                      <div
                        v-for="(subPage, i) in getSubPages(page.SubPages)"
                        :key="i"
                      >
                        <div v-if="subPage.Enabled">
                          <v-hover v-slot="{ hover }">
                            <router-link
                              :key="subPage._id.$oid"
                              :to="{
                                name: 'editPage',
                                params: { id: subPage._id.$oid },
                              }"
                            >
                              <div
                                :class="{ 'menu-item-hover': hover }"
                                class="colorclass"
                              >
                                <v-list-item
                                  subheader
                                  class="iconPadding max-hight"
                                >
                                  <v-list-item-icon
                                    class="my-2 mr-4"
                                    v-if="
                                      getSubpageIcon(subPage._id.$oid) != ''
                                    "
                                  >
                                    <v-icon class="menu-icon">{{
                                      getSubpageIcon(subPage._id.$oid)
                                    }}</v-icon>
                                  </v-list-item-icon>
                                  <div class="pl-10" v-else>
                                    <v-spacer></v-spacer>
                                  </div>
                                  <v-list-item-subtitle class="black--text">
                                    {{ getNameFromPageId(subPage._id.$oid) }}
                                  </v-list-item-subtitle>
                                </v-list-item>
                              </div>
                            </router-link>
                          </v-hover>
                        </div>
                      </div>
                    </div>
                  </v-sheet>
                </v-expand-transition>
              </template>
              <v-hover v-slot="{ hover }" v-else>
                <v-list-item
                  @click="goToRoute('editPage', page._id.$oid, page)"
                  :class="[
                    hover && !page['Group']
                      ? 'menu-item-hover'
                      : 'menu-item-hover-group',
                  ]"
                >
                  <template>
                    <router-link
                      :is="page.Group ? 'span' : 'router-link'"
                      :key="page._id.$oid"
                      :to="{
                        name: 'editPage',
                        params: { id: page._id.$oid },
                      }"
                    >
                      <v-layout>
                        <v-list-item-icon class="my-0 mr-4" v-if="page.Icon">
                          <v-icon class="menu-icon">{{ page.Icon }}</v-icon>
                        </v-list-item-icon>
                        <div class="pl-10" v-else>
                          <v-spacer></v-spacer>
                        </div>
                        <v-list-item-title
                          v-if="page.Name[currentLanguage].length == 0"
                          class="text-truncate black--text maxtext-width"
                        >
                          {{ page.Name[Object.keys(page.Name)[0]] }}
                        </v-list-item-title>
                        <v-list-item-title
                          v-else
                          class="text-truncate black--text maxtext-width"
                        >
                          {{ page.Name[currentLanguage] }}
                        </v-list-item-title>
                      </v-layout>
                    </router-link>
                  </template>
                </v-list-item>
              </v-hover>
            </v-list>
          </template>
        </template>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      defaultSelected: "",
      navSearchValue: "",
      openPage: [],
    };
  },
  computed: {
    ...mapGetters({
      selectedWebsite: "selectedWebsite",
      websites: "allWebsites",
    }),
    drawer: {
      get() {
        return this.$store.getters.drawer;
      },
      set(value) {
        this.$store.dispatch("setDrawer", value);
      },
    },
    userIsAdministrator() {
      return this.$auth.userIsAdministrator();
    },
    userIsUserManager() {
      return this.$auth.userIsUserManager();
    },
    currentLanguage: {
      get: function () {
        return this.$i18n.locale();
      },
    },
    selectedWebsiteSortedPages: {
      get: function () {
        this.setDefaultSelected(this.selectedWebsite);
        const sortedWebsites = this.selectedWebsite.Pages.slice().sort(
          this.$helpers.sortByKey(this.currentLanguage)
        );
        let allWebsitePages = [];
        sortedWebsites.forEach((element, index) => {
          const checkName = element.Name[this.currentLanguage]
            .toLowerCase()
            .includes(
              this.navSearchValue ? this.navSearchValue.toLowerCase() : ""
            );
          element.SubPages.forEach((subpage) => {
            if (
              this.$route.params.id == subpage.$oid &&
              !Object.prototype.hasOwnProperty.call(this.openPage, index)
            ) {
              this.$set(this.openPage, index, 1);
            }

            if (checkName && element.IsSubPage) {
              if (
                subpage.$oid == element._id.$oid &&
                !allWebsitePages.filter((e) => e.Name != element.Name).length >
                  0
              ) {
                if (
                  this.navSearchValue
                    ? this.navSearchValue.toLowerCase()
                    : "" == element.Name[this.currentLanguage].toLowerCase()
                ) {
                  let pageCopy = Object.assign({}, element);
                  pageCopy.SubPages = pageCopy.SubPages.filter(
                    (el) => el.$oid == subpage.$oid
                  );
                  allWebsitePages.push(pageCopy);
                } else {
                  allWebsitePages.push(element);
                }
              }
            }
          });
          if (checkName) {
            allWebsitePages.push(element);
          }
        });
        return allWebsitePages.filter((v, i, a) => a.indexOf(v) === i);
      },
    },
  },
  methods: {
    checkForPages() {
      const subpages = this.selectedWebsite.Pages.filter(
        (el) => el.IsSubPage == true && el.Enabled == true
      );

      const pagesWithoutSubPages =
        this.selectedWebsite.Pages.filter((el) => el.Enabled == true).length -
        subpages.length;
      return pagesWithoutSubPages > 11;
    },
    setDefaultSelected(value) {
      this.$store.dispatch("setSelectedWebsite", value);
      this.defaultSelected = value;
    },
    goToRoute(name, pageId, page) {
      if (page["Group"]) {
        return;
      }

      if (this.$route.params.id != pageId) {
        this.$router.replace({
          name: name,
          params: { id: pageId },
        });
        this.$router.go(1);
      }
    },
    checkForIconUpDown(page) {
      const sortedWebsites = this.selectedWebsite.Pages.slice().sort(
        this.$helpers.sortByKey(this.currentLanguage)
      );
      let hasAccess = false;
      page.SubPages.forEach((el) => {
        if (sortedWebsites.find((e) => e._id.$oid == el.$oid)) {
          hasAccess = true;
        }
      });

      return hasAccess;
    },
    slideOpen(index) {
      this.$set(this.openPage, index, !this.openPage[index]);
    },
    getSubPages(subpages) {
      let pages = [];
      subpages.forEach((element) => {
        let page = this.selectedWebsite.Pages.find(
          (p) => p._id.$oid === element.$oid
        );
        if (page) {
          pages.push(page);
        }
      });

      pages = pages.sort(this.$helpers.sortByKey(this.currentLanguage));
      return pages;
    },
    getSubpageIcon(id) {
      return this.selectedWebsite.Pages.find((p) => p._id.$oid === id).Icon;
    },
    getNameFromPageId(id) {
      return this.selectedWebsite.Pages.find((p) => p._id.$oid === id).Name[
        this.currentLanguage
      ];
    },
    setSelectedWebsite(value) {
      this.$store.dispatch("setSelectedWebsiteById", value);
      const selectedWebsiteSortedPagesEnabled =
        this.selectedWebsiteSortedPages.filter((page) => page.Enabled == true);
      this.$router.push({
        name: "editPage",
        params: { id: selectedWebsiteSortedPagesEnabled[0]._id.$oid },
      });
      this.$router.go(1);
    },
  },
};
</script>

<style scoped>
.websiteSelect,
.websiteSelect .input-group__selections,
.websiteSelect i {
  margin-top: -8px;
}

.websiteSelect .input-group__details {
  display: none;
}
.menu-item-hover {
  background: #ddd;
  cursor: pointer;
}
.menu-item-hover-group {
  cursor: default !important;
}
.router-link-active .menu-icon {
  color: var(--v-primaryColor-base) !important;
}
.maxtext-width {
  max-width: 150px;
}
.iconPadding {
  margin: 0 2rem 0rem 2.5rem;
}
.max-hight {
  min-height: 32px !important;
}
@media screen and (min-width: 820px) {
  .maxtext-width {
    max-width: 175px;
  }
}
</style>